.freemium {
  transition: 0.3s;

  &.freemium--collapse {
    height: 108px;

    &.freemium--divider .freemium__title, .freemium__title {
      margin-bottom: 0;

      .subhead {
        opacity: 0;
      }
    }

    &.freemium--divider, &.freemium--success, &.freemium--primary, &.freemium--warning, &.freemium--danger {
      height: 72px;
    }

    .freemium__body {
      padding: 35px 35px 35px 30px;
    }
  }

  &.freemium--narrow {

    .freemium__title {
      margin-bottom: 0;
    }

  }

  &.freemium--divider {

    .freemium__body {
      padding: 0;
    }

    .freemium__title {
      padding: 20px 40px 15px 30px;
      margin-bottom: 15px;

      @include themify($themes) {
        border-bottom: solid 1px themed('colorBorder');
      }
    }

    .freemium__btns {
      top: 20px;
    }

    .freemium__content {
      padding: 0 35px 40px 30px;
    }
  }

  &.freemium--success, &.freemium--primary, &.freemium--warning, &.freemium--danger {

    .freemium__body {
      padding: 0;
    }

    .freemium__title {
      padding: 20px 40px 15px 30px;
      margin-bottom: 15px;

      h5 {
        color: white;
      }
    }

    .freemium__btns {
      top: 20px;

      svg {
        fill: #ffffff;
      }
    }

    .freemium__content {
      padding: 0 35px 40px 30px;
    }
  }

  &.freemium--success {

    .freemium__title {
      @include themify($themes) {
        background-color: themed('colorAccent');
      }
    }
  }

  &.freemium--danger {

    .freemium__title {
      background-color: $color-red;
    }
  }

  &.freemium--warning {

    .freemium__title {
      background-color: $color-yellow;
    }
  }

  &.freemium--primary {

    .freemium__title {
      @include themify($themes) {
        background-color: themed('colorPrimary');
      }
    }
  }
}

.freemium__count-alert {
  width: 26px;
  height: 14px;
  background-color: red;
  color: white;
  font-weight: 400;
  padding: 3px;
  margin-left: 5px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 10px;

  span {
    position: absolute;
    left: 0;
    top: 3px;
    width: 26px;
    text-align: center;
  }
}

.freemium_top_border_alert {
  border-top: 2px solid;
  border-top-color: red;
}

.freemium_alert-icon {
  margin-right: 10px;
  font-size: 13px;
  line-height: 13px;
  color: red;
}

.freemium_alert-img {
  font-size: 25px;
  line-height: 36px;
  height: 35px;
  vertical-align: top;
  display: inline-block;
  color: red;
}

.freemium__count-performance {
  width: 26px;
  height: 14px;
  background-color: green;
  color: white;
  font-weight: 400;
  padding: 3px;
  margin-left: 5px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 10px;

  span {
    position: absolute;
    left: 0;
    top: 3px;
    width: 26px;
    text-align: center;
  }
}

.freemium_top_border_performance {
  border-top: 2px solid;
  border-top-color: green;
}

.freemium_performance-icon {
  margin-right: 10px;
  font-size: 13px;
  line-height: 13px;
  color: green;
}

.freemium_performance-img {
  font-size: 30px;
  line-height: 36px;
  height: 40px;
  padding-right: 10px;
  vertical-align: top;
  display: inline-block;
  color: green;
}

.freemium_platform-img {
  font-size: 35px;
  line-height: 36px;
  height: 40px;
  width: 40px;
  display: inline-block;
}

.freemium_platform-text {
  font-size: min(4vw, 40px);
  line-height: 20px;
  height: 40px;
  vertical-align: top;
  line-height: 36px;
  display: inline-block;

  @include themify($themes) {
    color: themed('colorText');
  }
}

.freemium_bottom-blank {
  height: 200px;
  background-color: lightgray;
}

.freemium__body {
  position: relative;
  padding: 30px 35px 50px 30px;
  transition: height 0.3s;
}

.freemium__card_header-right {
  position: absolute;
  top: 40px;
  right: 40px;
}

.freemium__btn {
  width: 14px;
  height: 14px;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-left: 10px;

  svg {
    width: 14px;
    height: 14px;
    fill: $color-additional;
  }

  &:focus {
    outline: none;
  }
}

.freemium__title {
  text-transform: uppercase;
  margin-bottom: 30px;
  transition: 0.3s;

  h5 {
    font-size: 13px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    transition: 0.3s;
  }
}

.freemium__label {
  left: 100%;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 13px;
  padding: 3px 10px;
  margin-left: 10px;
  @include themify($themes) {
    background-color: themed('colorAccent');
  }
}

.freemium__icon {
  margin-right: 5px;
}

.freemium__green-icon-text {
  font-size: 40px;
  vertical-align: middle;
  color: $color-green;
}

.freemium__red-icon-text {
  font-size: 40px;
  vertical-align: middle;
  color: $color-red;
}
